const React = require('react');
const { bool, shape, string } = require('prop-types');

// eslint-disable-next-line import/order
const { loadable } = require('nordic/lazy');

const BusinessTools = loadable(async () =>
  import('@pog-ui-landings-library/business-tool'),
);
const CardList = loadable(async () =>
  import('@pog-ui-landings-library/card-list'),
);
const Faqs = loadable(async () => import('@pog-ui-landings-library/faqs'));
const FilteredCards = loadable(async () =>
  import('@pog-ui-landings-library/filtered-cards'),
);
const GenericComponent = loadable(async () =>
  import('@pog-ui-landings-library/generic-component'),
);
const Hero = loadable(async () => import('@pog-ui-landings-library/hero'));
const Payments = loadable(async () =>
  import('@pog-ui-landings-library/payments'),
);
const PaymentMethods = loadable(async () =>
  import('@pog-ui-landings-library/payment-methods'),
);
const PaymentMethodsSection = loadable(async () =>
  import('@pog-ui-landings-library/payment-methods-section'),
);
const PreFooterSection = loadable(async () =>
  import('@pog-ui-landings-library/prefooter-section'),
);
const Taxes = loadable(async () => import('@pog-ui-landings-library/taxes'));
const Tyc = loadable(async () => import('@pog-ui-landings-library/tyc'));
const TycSection = loadable(async () =>
  import('@pog-ui-landings-library/tyc-section'),
);

const CardListSection = require('@pog-ui-landings-library/card-list-section');
const CardsMediaSection = require('@pog-ui-landings-library/cards-media-section');
const FaqsSection = require('@pog-ui-landings-library/faqs-section');
const FeesSection = require('@pog-ui-landings-library/fees-section');
const FixedActionsSection = require('@pog-ui-landings-library/fixed-actions-section');
const FormSection = require('@pog-ui-landings-library/form-section');
const HeroSection = require('@pog-ui-landings-library/hero-section');
const ImageTextSection = require('@pog-ui-landings-library/image-text-section');
const ListSection = require('@pog-ui-landings-library/list-section');
const ModalSection = require('@pog-ui-landings-library/modal-section');
const MultiproductWrapper = require('@pog-ui-landings-library/multiproduct-wrapper');
const NavBar = require('@pog-ui-landings-library/navbar');
const NavBarSection = require('@pog-ui-landings-library/navbar-section');
const Opportunity = require('@pog-ui-landings-library/opportunity');
const RemoteModule = require('@pog-ui-landings-library/remote-module'); // es-lint-disable-line: import/no-unresolved
const ShortcutsSection = require('@pog-ui-landings-library/shortcuts-section');
const TaxesHybrid = require('@pog-ui-landings-library/taxes-hybrid');
const TitleOnlySection = require('@pog-ui-landings-library/title-only-section');

const lazyWrapper = require('../../../utils/lazy-wrapper');

const CalmComponent = ({
  id,
  config,
  type,
  moduleKey,
  isMobile,
  isWebView,
  theme,
}) => {
  const { lazyMode } = config || {};

  switch (type) {
    case 'business-tool':
      return lazyWrapper(
        <BusinessTools
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'card-list':
      return lazyWrapper(
        <CardList
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'card-list-section':
      return lazyWrapper(
        <CardListSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />,
        lazyMode,
      );

    case 'cards-media-section':
      return lazyWrapper(
        <CardsMediaSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />,
        lazyMode,
      );

    case 'faqs':
      return lazyWrapper(
        <Faqs {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'faqs-section':
      return (
        <FaqsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'fees-section':
      return (
        <FeesSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'filtered-cards':
      return lazyWrapper(
        <FilteredCards
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'fixed-actions-section':
      return (
        <FixedActionsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'form-section':
      return (
        <FormSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />
      );

    case 'generic-component':
      return lazyWrapper(
        <GenericComponent
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        config?.video ? 'none' : lazyMode,
      );

    case 'hero':
      return lazyWrapper(
        <Hero {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'hero-section':
      return (
        <HeroSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />
      );

    case 'image-text-section':
      return (
        <ImageTextSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />
      );

    case 'list-section':
      return (
        <ListSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />
      );

    case 'modal-section':
      return (
        <ModalSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          isAdmin={false}
        />
      );

    case 'multiproduct-wrapper':
      return lazyWrapper(
        <MultiproductWrapper
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'navbar':
      return (
        <NavBar {...config} id={id} isMobile={isMobile} isWebView={isWebView} />
      );

    case 'navbar-section':
      return (
        <NavBarSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'opportunity':
      return (
        <Opportunity
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'payments':
      return lazyWrapper(
        <Payments
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'payment-methods':
      return lazyWrapper(
        <PaymentMethods
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'payment-methods-section':
      return lazyWrapper(
        <PaymentMethodsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />,
        lazyMode,
      );

    case 'prefooter-section':
      return lazyWrapper(
        <PreFooterSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode || 'whenVisible',
      );

    case 'remote-module': {
      const { remoteModule } = config;

      if (!remoteModule) {
        return null;
      }

      const { name, host, moduleSetup } = remoteModule;

      if (!name || !host) {
        return null;
      }

      return (
        <RemoteModule
          {...remoteModule}
          id={id}
          isMobile={isMobile}
          data={moduleSetup}
          moduleId={moduleKey}
          isWebView={isWebView}
        />
      );
    }

    case 'shortcuts-section':
      return (
        <ShortcutsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />
      );

    case 'taxes':
      return lazyWrapper(
        <Taxes {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'taxes-hybrid':
      return lazyWrapper(
        <TaxesHybrid
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'title-only-section':
      return (
        <TitleOnlySection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
        />
      );

    case 'tyc':
      return lazyWrapper(
        <Tyc {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'tyc-section':
      return lazyWrapper(
        <TycSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    default:
      return null;
  }
};

CalmComponent.propTypes = {
  id: string,
  type: string,
  config: shape({
    lazyMode: string,
  }),
  moduleKey: string,
  isMobile: bool,
  isWebView: bool,
  theme: string,
};

module.exports = CalmComponent;
